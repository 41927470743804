import { useEffect, useState } from "react";
import { BOOKING_CHECK_FLIGHT, ITRAVEL_KEY_API, MAIN_LAMBDA } from "../utils/constants";
import { GET } from "../utils/api";
import {
  definitionsWithCombinationsBaggage,
  formatBaggageDefinitions,
  getItemFromLocalStorage,
} from "../common/methods";
import { PARTNERID ,SIGNATURE} from "../utils/enums";
import { useStoreFly } from "../store";
 
const baggageForBooking = (combinations, definitions) => { 
  if (!combinations && !definitions) return; 
  const definitionHandBag = definitions?.baggageWeight;
  const definitionHoldBag = definitions?.baggageWeight;
  const combinationHandBag = combinations?.baggageWeight;
  const combinationHoldBag = combinations?.baggageWeight;

  const formattedDefinitions = formatBaggageDefinitions(
    definitionHandBag,
    definitionHoldBag
  );
  const formattedCombinations = formatBaggageDefinitions(
    combinationHandBag,
    combinationHoldBag
  );

  const { handBags, holdBags } = definitionsWithCombinationsBaggage(
    formattedDefinitions?.formattedHandBag,
    formattedDefinitions?.formattedHoldBag,
    formattedCombinations?.formattedHandBag,
    formattedCombinations?.formattedHoldBag,
    0
  );

  const handBagsToPay = handBags?.filter((handB) => !handB?.is_free);
  const holdBagsToPay = holdBags?.filter((holdB) => !holdB?.is_free);
  const handBagsWithDimensions = handBags?.filter(
    (handB) => handB?.dimensions && handB.is_free
  );
  const holdBagsWithDimensions = holdBags?.filter(
    (holdB) => holdB?.dimensions && holdB.is_free
  );
  return {
    handBags,
    holdBags,
    handBagsWithDimensions,
    holdBagsWithDimensions,
    handBagsToPay,
    holdBagsToPay,
  };
};

export const useCheckFlight = (
  flight,
  currency,
  adult,
  children,
  babies,
  // bnum
) => 
  { 
  adult?adult=adult:adult=1;
  children?children=children:children=0;
  babies?babies=babies:babies=0;
  const [price, setPrice] = useState(flight?.totalPrice);
  const [baggageInfoFinal, setBaggageInfoFinal] = useState();
  const [bookingFlightDetails, setBookingFlightDetails] = useState();
  const bnum = getItemFromLocalStorage("bnum");
  // const updatePreciseSearchData =useStoreFly((state)=>state.updatePreciseSearchData());
  useEffect(() => {
    const checkFlights = async () => {
      // KOMENT qitu osht api per me check flights bnum osht totali i bagazheve 
      // (kqyre api dokumentimin qitu qysh funksionon https://tequila.kiwi.com/portal/docs/tequila_api/booking_api)
      var myHeaders = new Headers(); 
      myHeaders.append("Content-Type", "application/json");

      function getJourneyData(journ ){ 
        let a =[]
        journ?.segmengtIds?.forEach((b)=>{

           let obj= {
            airline:b.airline,
            flightNum:b.flightNum,
           arrival:b.arrival,
           arrivalDate:b.strArrivalDate,
           arrivalTime:b.strArrivalTime,
           departure:b.departure,
           departureDate:b.strDepartureDate,
           departureTime:b.strDepartureTime,
           bookingCode:b.bookingCode,
         } 
         a.push(obj)
        })
       
      return a
      }

      let journeys={}
      console.log('flight', flight)
      if(flight?.journeys?.journey_0){
        journeys.journey_0=getJourneyData(flight.journeys.journey_0)
        if(flight?.journeys?.journey_1){
          journeys.journey_1=getJourneyData(flight.journeys.journey_1)

        }
      }

      var raw = JSON.stringify({ 
        "authentication": {
           "partnerId": PARTNERID,
           "sign": SIGNATURE
        },
      "pricing": {
        "journeys": journeys,
        "adults": flight.adults,
        "children": flight.children,
        "infants": flight.infants,
        "solutionId": flight.solutionId,
        "cabin": flight.cabin
       }
     });
     
     var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
     };
     let data ;
     await fetch(MAIN_LAMBDA+"/precise-pricing", requestOptions)
        .then(response => response.json())
        .then(result => { 
          data=joinSegments(result.data) 
          
        })
        

        function joinSegments(data) {  
          if(data){

            let segments = data?.segments
            let flights = data?.flights
            let solutions =[data?.solution] 
            solutions.forEach((el)=>{
             let adultPrice= el.adtFare+el.adtTax+el.tktFee;
             let childPrice= el.chdFare+el.chdTax+el.tktFee;
             let infantPrice= (el.infFare?el.infFare:0)+(el.infTax?el.infTax:0)+el.tktFee;
             let totalPrice=(adultPrice*el.adults)+(childPrice*el.children)+(infantPrice*(el.infant?el.infant:0))+el.platformServiceFee+el.merchantFee;
             let journKeys=Object.keys(el.journeys)
             
             el.totalPrice=totalPrice
             journKeys.forEach((journ)=>{ 
               let tempJournObj=flights.find((fl)=>fl.flightId===el.journeys[journ][0])
               el.journeys[journ]=tempJournObj
               let tempSegments=[]
               el.journeys[journ].segmentIds.forEach((journSegment)=>{   
                 let a
                 if(journ=='journey_0'){
                    a=false
                 }
                 else{
                   
                     a =true
                 }
                 tempSegments.push({... segments.find((jourSegmentId)=>jourSegmentId.segmentId===journSegment),...{isReturn:a}}) 
               })
               el.journeys[journ].segmentIds=tempSegments
               })
               el.totalJourney=el.journeys.journey_0.journeyTime+el.journeys.journey_1?.journeyTime
             })  
             return solutions
          }
          else{
            return []
          }
       }


       var rawAncillary = JSON.stringify({ 
        "authentication": {
           "partnerId": PARTNERID,
           "sign": SIGNATURE
        },
      "pricing": {
        "adults": flight.adults,
        "children": flight.children,
        "ancillary": [
            1
        ],
        "solutionId": flight.solutionId,
        "journeys": {
         "journey_0":  getJourneyData(flight.journeys.journey_0),
         "journey_1": getJourneyData(flight.journeys.journey_1)
      },
    }
     }); 
     
     var requestOptionsAncillary = {
        method: 'POST',
        headers: myHeaders,
        body: rawAncillary,
        redirect: 'follow'
     };
      
     await fetch(MAIN_LAMBDA+"/ancillary-pricing", requestOptionsAncillary)
        .then(response => response.json())
        .then(result => { 
          localStorage.setItem('ancillaryData',JSON.stringify(result.data))
          mergeAncillaryWithSegments(result?.data,data[0])
          
        })
        
 
      // const data = await GET(
      //   `${BOOKING_CHECK_FLIGHT}?booking_token=${flight?.booking_token}&bnum=${
      //     bnum?.checked || 0
      //   }&adults=${adult}&children=${children}&infants=${babies}&currency=${
      //     currency?.curr
      //   }&first_request=true`,
      //   {},
      //   ITRAVEL_KEY_API
      // );
      // if (data?.price_change) {
      //   setPrice(data?.total);
      // }
      function mergeAncillaryWithSegments(ancillary,data){  
        if(data&&ancillary){

          const finalJournKeys=Object.keys(data?.journeys) 
          ancillary?.forEach((ancill)=>{ 
            ancill.ancillary.journeys.forEach((journ)=>{
              finalJournKeys.forEach((finalJourn)=>{
                data.journeys[finalJourn].segmentIds.forEach((finalSegments)=>{
                  if(finalSegments.flightNum===journ.flightNum){
                    finalSegments.ancillary=journ
                    finalSegments.baggageInfo=ancill.ancillary.ancillaries
                  }
                })
              })
            })
          })
        }
        
      } 
      localStorage.setItem('preciseSearchData',JSON.stringify(data)) 
      setBookingFlightDetails(data);
    };
    checkFlights();
  }, [
    adult,
    babies,
    children,
    bnum?.checked,
    currency?.curr,
    flight.adults,
    flight.babies,
    flight.bnum,
    flight?.booking_token,
    flight.children,
  ]);

  const baggageInfo =bookingFlightDetails?bookingFlightDetails[0]?.journeys:undefined
  //    bookingFlightDetails[0].journeys.journey_0.segmentIds[0].baggageInfo,
  //    bookingFlightDetails[0].journeys.journey_0.segmentIds[0].baggageInfo
  //   );
     
  return {
    price,
    setPrice,
    bookingFlightDetails,
    handBagDimensions: baggageInfo?.handBagsWithDimensions,
    holdBagDimensions: baggageInfo?.holdBagsWithDimensions,
    holdBagsToPay: baggageInfo?.holdBagsToPay || [],
    baggageInfo,
  };
};
