import moment from "moment";
import { GET, POST } from "../utils/api";
import {
  BOOKING_CHECK_FLIGHT,
  BOOKING_SAVE,
  GET_IMAGE_FOR_CITY,
  GET_LIST_COUNTRY,
  GET_LOCATIONS_BY_BOX,
  GET_LOCATIONS_QUERY_PARAMS,
  GET_LOCATION_BY_ID,
  GET_MULTICITY_REQUEST_PARAMS,
  ITRAVEL_KEY_API,
  MULTICITY_KEY_API,
  MAIN_LAMBDA,
  MULTI_CITY_ENDPOINT,
  SEARCH_API,
  SEARCH_API_QUERY_PARAMS,
} from "../utils/constants";
import {
  FLIGHT_TIMES,
  MAXIMUM_STOPS,
  RETURN_TYPE,
  SELECTED_CABINS,
  SELECTED_CLASS,
  airports as AIRPORTS
} from "../utils/enums";
import {
  calculateTotalTime,
  combineDateAndTime,
  convertSecondsToHoursMinutes,
  getFormattedTime,
  getHowManyStops,
  getItemFromLocalStorage,
  getTodayAndOneMonthFromNow,
  removeDuplicatesFromArray,
  toHoursAndMinutes,
  uniqueItems,
} from "./methods";
import { GEOLOCATION_COORDINATES, PARTNERID, SIGNATURE } from "../utils/enums";

export class FlightUtils {
  constructor(flight) {

    this.flight = flight;
  }


  async setFlights(
    flightSet,
    setTotalFlights,
    setFilter,
    filters,

    carriers,
    locationPath,
    navigate
  ) {
    if (locationPath === "/home" || locationPath === "/") {
      navigate("/search");
    }
    const {
      dateFrom,
      dateTo,
      flyFrom,
      flyTo,
      adults,
      children,
      babies,
      selectedClass,
      adultCabin,
      adultChecked,
      selectedAirlines,
      maxStops,
      flightTimeGoing,
      flightTimeReturning,
      returnOrOneWay,
      curr,
      cityNameFrom,
      cityNameTo,
      returnFromDate,
      returnToDate
    } = filters;



    const timesGoing = FLIGHT_TIMES.departure[flightTimeGoing];
    const timesReturning = FLIGHT_TIMES.arrival[flightTimeReturning];
    let searchParams = SEARCH_API_QUERY_PARAMS({
      flyFrom,
      flyTo,
      dateFrom,
      dateTo,
      adults,
      children,
      babies,
      selectedClass,
      vehicleType: "aircraft",
      currency: "EUR",
      limit: 30,
      selectedAirlines,
      aTimeFrom: timesGoing?.atime_from,
      aTimeTo: timesGoing?.atime_to,
      dtimeFrom: timesGoing?.dtime_from,
      dtimeTo: timesGoing?.dtime_to,
      retAtimeFrom:
        returnOrOneWay === "return" ? timesReturning?.ret_atime_from : null,
      retAtimeTo:
        returnOrOneWay === "return" ? timesReturning?.ret_atime_to : null,
      retDtimeFrom:
        returnOrOneWay === "return" ? timesReturning?.ret_dtime_from : null,
      retDtimeTo:
        returnOrOneWay === "return" ? timesReturning?.ret_dtime_to : null,
      returnFromDate: returnOrOneWay === "return" ? returnFromDate : null,
      returnToDate: returnOrOneWay === "return" ? returnFromDate : null,
      curr,
    });



    let max_stopovers = null;
    if (maxStops && maxStops !== "any") {
      max_stopovers = `max_stopovers=${MAXIMUM_STOPS[maxStops]}&`;
    }

    let baggageCount = "";

    if (adults && adultChecked.length) {
      baggageCount += `&adult_hold_bag=${adultChecked}`;
    }
    if (adults && adultCabin.length) {
      baggageCount += `&adult_hand_bag=${adultCabin}&`;
    }

    if (baggageCount) {
      searchParams += baggageCount;
    }

    if (max_stopovers !== null && max_stopovers !== undefined) {
      searchParams += max_stopovers;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    function dateFormat(date) {
      let splitdate = date.split('/')
      return splitdate[2] + '-' + splitdate[1] + '-' + splitdate[0]
    }
    var finalRes = [];

    let airlegs = filters.returnOrOneWay === 'oneway' ? [
      {
        "cabinClass": filters.selectedClass ? filters.selectedClass : 'ECONOMY',
        "departureDate": dateFormat(filters.dateFrom ? filters.dateFrom : ''),
        "destination": filters.flyTo ? filters.flyTo : '',
        "origin": filters.flyFrom ? filters.flyFrom : ''
      },
    ] : [
      {
        "cabinClass": filters.selectedClass ? filters.selectedClass : 'ECONOMY',
        "departureDate": dateFormat(filters.dateFrom ? filters.dateFrom : ''),
        "destination": filters.flyTo ? filters.flyTo : '',
        "origin": filters.flyFrom ? filters.flyFrom : ''
      },
      {
        "cabinClass": filters.selectedClass ? filters.selectedClass : 'ECONOMY',
        "departureDate": dateFormat(filters.dateTo ? filters.dateTo : ''),
        "destination": filters.flyFrom ? filters.flyFrom : '',
        "origin": filters.flyTo ? filters.flyTo : ''
      }
    ]
    var raw = JSON.stringify({
      "authentication": {
        "partnerId": PARTNERID,
        "sign": SIGNATURE
      },
      "search": {
        "adults": filters.adults ? filters.adults : 1,
        "children": filters.children ? filters.children : 0,
        "infants": filters.infants ? filters.infants : 0,
        "nonstop": filters.maxStops == 'nonstop' ? 1 : 0,
        "airline": Array.isArray(filters.selectedAirlines) ? filters.selectedAirlines.join(',') : '',
        "solutions": 0,
        "searchAirLegs": airlegs
      }
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    await fetch(MAIN_LAMBDA + "/shopping", requestOptions)
      .then(response => response.json())
      .then(result => {
        localStorage.removeItem('ancillaryData')
    localStorage.removeItem('currentBooking')
    localStorage.removeItem('preciseSearchData')
        finalRes = joinSegments(result.data)
      }).catch((err) => {

      })
    

    function joinSegments(data) {
      let segments = data.segments
      let flights = data.flights
      let solutions = data.solutions 
      solutions.forEach((el) => {
        let adultPrice = el.adtFare + el.adtTax + el.tktFee;
        let childPrice = el.chdFare + el.chdTax + el.tktFee;
        let infantPrice = (el.infFare ? el.infFare : 0) + (el.infTax ? el.infTax : 0) + el.tktFee;
        let totalPrice = (adultPrice * el.adults) + (childPrice * el.children) + (infantPrice * (el.infant ? el.infant : 0)) + el.platformServiceFee + el.merchantFee;
        let journKeys = Object.keys(el.journeys)

        el.totalPrice = totalPrice
        journKeys.forEach((journ) => {
          let tempJournObj = flights.find((fl) => fl.flightId === el.journeys[journ][0])
          el.journeys[journ] = tempJournObj
          let tempSegments = []
          el.journeys[journ].segmengtIds.forEach((journSegment) => {
            let a
            if (journ == 'journey_0') {
              a = false
            }
            else {

              a = true
            }
            tempSegments.push({ ...segments.find((jourSegmentId) => jourSegmentId.segmentId === journSegment), ...{ isReturn: a } })
          })
          el.journeys[journ].segmengtIds = tempSegments
        })
        el.totalJourney = el.journeys?.journey_0?.journeyTime + el.journeys?.journey_1?.journeyTime
      })
      return solutions
    }

    const [flightsByPrice, flightsByQuality, flightsByDuration] = await Promise.allSettled([
      finalRes, finalRes, finalRes
    ]);

    const formattedFlightsByPrice = finalRes.sort((a, b) => a.totalPrice - b.totalPrice).slice(0, 30)
    const formattedFlightsByQuality = finalRes.sort((a, b) => (a.totalJourney + a.totalPrice) - (b.totalJourney + b.totalPrice)).slice(0, 30)
    const formattedFlightsByDuration = finalRes.sort((a, b) => a.totalJourney - b.totalJourney).slice(0, 30)

    const flightsData = [
      ...(formattedFlightsByPrice || []),
      ...(formattedFlightsByQuality || []),
      ...(formattedFlightsByDuration || []),
    ];

    const searchAirlines = this.listSearchAirlines(flightsData, carriers);

    const total =
      formattedFlightsByPrice.length +
      formattedFlightsByQuality.length +
      formattedFlightsByDuration.length;

    setTotalFlights(total);
    setFilter("filterAirlines", "list", searchAirlines);
    setFilter("filterAirlines", "selected", searchAirlines);

    flightSet(formattedFlightsByPrice, "flightsByPrice");
    flightSet(formattedFlightsByQuality, "flightsByQuality");
    flightSet(formattedFlightsByDuration, "flightsByDuration");

    localStorage.setItem("flightsByPrice", JSON.stringify(formattedFlightsByPrice));
    localStorage.setItem("flightsByQuality", JSON.stringify(formattedFlightsByQuality));
    localStorage.setItem("flightsByDuration", JSON.stringify(formattedFlightsByDuration));
  }

  formatTopLocationsForMultiSearch(
    topLocations = [],
    flyFrom,
    tab,
    returnOrOneWayTopLocations
  ) {

    if (!topLocations.length) return [];
    const formattedLocations = [];
    const { today, tomorrow, aMonthFromNow, twoDaysFromToday } = getTodayAndOneMonthFromNow();

    topLocations.forEach((location) => {
      const depDateRange =
        returnOrOneWayTopLocations === "return"
          ? [today, aMonthFromNow]
          : [tomorrow, aMonthFromNow];
      formattedLocations.push({
        fly_from: [flyFrom || "PRN"],
        fly_to: [tab === "popular" ? location?.code : location?.city?.id],
        dep_date_range: depDateRange,
      });
    });
    return formattedLocations;
  }

  resetFilters(setFilters) {
    const initialFilters = {
      filters: {
        baggage: {
          cabin: 0,
          checked: 0,
          maximumOfCheckedBag: 2,
          maximumOfCabinBag: 1,
        },
        stops: {
          current: "any",
          allowOvernight: true,
        },
        flightTimes: {
          going: {
            time: "",
          },
          returning: {
            time: "",
          },
        },
        direction: "oneway",
        class: SELECTED_CLASS.Economy,
        passengers: {
          adult: 1,
          children: 0,
          babies: 0,
        },
        route: {
          origin: "",
          destination: "",
          dateFrom: "",
          dateTo: "",
        },
        airlines: [],
      },
    };
    setFilters("", initialFilters);
  }

  async setAirportNames(
    currentFlights = [],
    flightId,
    setAirportNamesByFlightId
  ) {
    const currentFlight = this.getFlightById(currentFlights, flightId);
    const flyFrom = currentFlight?.journeys.journey_0.segmengtIds?.map(
      (selectedFlightDetail) => selectedFlightDetail?.departure
    );
    const flyTo = currentFlight?.journeys.journey_0.segmengtIds?.map(
      (selectedFlightDetail) => selectedFlightDetail?.arrival
    );
    const directions = [...flyFrom, ...flyTo];

    const locations = 'N/A'
    setAirportNamesByFlightId(flightId, locations);
  }

  getFlightById(flight, flightId) {
    const data = flight?.data || [];
    return data?.find((item) => item.solutionId === flightId);
  }

  getAirportNameByCityCode(cityCodeFrom, cityCodeTo, airports = []) {
    const fromAirport = AIRPORTS?.find(
      (airport) => airport.id === cityCodeFrom
    );
    const toAirport = AIRPORTS?.find((airport) => airport.id === cityCodeTo);

    return {
      departFromAirportName: fromAirport,
      arriveToAirportName: toAirport,
    };
  }

  getAirlineNames(airlinesId = [], airlines = []) {
    const result = [];
    airlines.forEach((airline) => {
      if (airline && airline.id && airlinesId.includes(airline.id)) {
        result.push(airline);
      }
    });
    return result;
  }

  getAirline(airlineId, airlines = []) {
    return airlines?.find((airline) => airline.id === airlineId);
  }

  getInboundAndOutboundAirlineNames(
    airlinesWithNames = [],
    inboundFlights = [],
    outboundFlights = []
  ) {
    const inboundAirlineNames = [];
    const outboundAirLineNames = [];

    airlinesWithNames?.forEach((airlineItem) => {
      inboundFlights?.forEach((inboundFlight) => {
        if (inboundFlight.airline === airlineItem.id)
          inboundAirlineNames.push(airlineItem);
      });
    });

    airlinesWithNames?.forEach((airlineItem) => {
      outboundFlights?.forEach((outboundFlight) => {
        if (outboundFlight.airline === airlineItem.id)
          outboundAirLineNames.push(airlineItem);
      });
    });

    return { inboundAirlineNames, outboundAirLineNames };
  }

  getInboundAndOutboundRoutes(routes) {
    const inboundFlights = routes?.journeys?.journey_1?.segmengtIds || [];
    const outboundFlights = routes?.journeys?.journey_0?.segmengtIds;
    // routes.forEach((route) => {
    //   if (route && route.return === 0) {
    //     outboundFlights.push(route);
    //   }
    //   if (route && route.return === 1) {
    //     inboundFlights.push(route);
    //   }
    // });
    return { inboundFlights, outboundFlights };
  }

  getMaximumBagCount(passengers) {
    const { adult, children, babies } = passengers;
    const totalOfPassengers = adult + children + babies;
    const totalForCabinBag = totalOfPassengers;
    const totalForCheckedBag = totalOfPassengers * 2;

    return { totalForCabinBag, totalForCheckedBag };
  }

  getAdultCheckedBaggage(adults = 0, totalBaggageSelected = 0) {
    const elementPosition = totalBaggageSelected - adults;

    let array = [];
    if (totalBaggageSelected >= adults) {
      array = Array.from({ length: adults }, (_, i) =>
        i < elementPosition ? 2 : 1
      );
    } else {
      array = Array.from({ length: adults }, (_, i) =>
        i >= totalBaggageSelected ? 0 : 1
      );
    }

    return array;
  }

  getAdultCabinBaggage(adults = 0, totalBaggageSelected = 0) {
    const elementPosition = adults - totalBaggageSelected;
    const array = Array.from({ length: adults }, (_, i) =>
      i < elementPosition ? 1 : 0
    );

    return array;
  }

  listSearchAirlines(flightResults = [], airlinesWithNames = []) {
    let airlines = [];
    const filteredAirlines = [];
    flightResults.forEach((flight) => {
      let tempAirLines = [];
      flight.journeys.journey_0.segmengtIds.forEach((seg) => {
        tempAirLines.push(seg.airline)
      })
      flight.journeys?.journey_1?.segmengtIds.forEach((seg) => {
        tempAirLines.push(seg.airline)
      })
      airlines.push(...tempAirLines);
      tempAirLines = []
    });

    let airlinesFin = Array.from(new Set(airlines));
    airlines = airlinesFin
    airlines.forEach((distinctAirline) => {
      airlinesWithNames.forEach((airlineWithName) => {
        if (distinctAirline === airlineWithName.id) {
          filteredAirlines.push(airlineWithName);
        }
      });
    });

    const idAndName = filteredAirlines?.map((filteredAirline) => ({
      id: filteredAirline?.id,
      name: filteredAirline.name,
      checked: true,
    }));

    const airlinesDistincted = removeDuplicatesFromArray(idAndName);

    return airlinesDistincted;
  }

  goingTimeAndReturning(timesGoing, timesReturning) {
    const departingTimes = `&dtime_from=${timesGoing?.dtime_from}&dtime_to=${timesGoing?.dtime_to}&atime_from=${timesGoing?.atime_from}&atime_to=${timesGoing?.atime_to}`;

    const arrivalTimes = `ret_dtime_from=${timesReturning?.ret_dtime_from}&ret_dtime_to=${timesReturning?.ret_dtime_to}&ret_atime_from=${timesReturning?.ret_atime_from}&ret_atime_to=${timesReturning?.ret_atime_to}`;

    return {
      departingTimes,
      arrivalTimes,
    };
  }







  flightDetails(currentRoutes = [], flight, flightType) { 
     
    const stops = getHowManyStops(currentRoutes);
    let departureTime = getFormattedTime(combineDateAndTime(flight?.journeys?.journey_0?.segmengtIds[0], true));  
    let arrivalTime = getFormattedTime(combineDateAndTime(flight?.journeys?.journey_0?.segmengtIds[flight?.journeys?.journey_0?.segmengtIds.length - 1], false));
    let localDep = flight?.journeys.journey_0?.segmengtIds[0].strDepartureDate;
    let localArr = flight?.journeys.journey_0?.segmengtIds[flight?.journeys?.journey_0?.segmengtIds.length - 1].strArrivalTime;
    const startingFlight = currentRoutes?.[0];
    console.log('startingFlight', startingFlight)
    const endingFlight = currentRoutes?.[currentRoutes?.length - 1];

    function calcDiff(date1, date2) {

      date1 = moment(combineDateAndTime(startingFlight, false));

      date2 = moment(combineDateAndTime(endingFlight, true));

      return Math.abs(date1.diff(date2, 'minutes'));
    }


    function returnFlightTime(routes,isReturn) { 
      console.log('routes', routes)
      let a =0;
      routes.forEach((el)=>{
        if(el.isReturn==isReturn){
          a+=el.flightTime
        } 
      })
      console.log('a', a)
      return a
      
    }
    const departureTimeConverted = convertSecondsToHoursMinutes(
      returnFlightTime(currentRoutes,false) 
      // startingFlight.flightTime

      // calcDiff(combineDateAndTime(startingFlight, false), combineDateAndTime(endingFlight, true))

    );

    const returnTimeConverted = convertSecondsToHoursMinutes(
      returnFlightTime(currentRoutes,true) 
      // endingFlight.flightTime
      // calcDiff(combineDateAndTime(currentRoutes[0], false), combineDateAndTime(endingFlight, true))

    );


    if (flightType === "inbound") {
      departureTime = getFormattedTime(
        combineDateAndTime(startingFlight, true)
         
      );
      arrivalTime = getFormattedTime(
        combineDateAndTime(endingFlight, false)

      );

      localDep = startingFlight?.strDepartureTime;
      localArr = endingFlight?.strArrivalTime;
    }

    const dep = moment(startingFlight?.strDepartureTime);
    const arr = moment(endingFlight?.strArrivalTime);

    const diffInHours = moment.duration(arr.diff(dep));
    const { hours, minutes } = toHoursAndMinutes(
      diffInHours.asMinutes()?.toFixed()
    );




    const cityCodeFrom =
      RETURN_TYPE[flightType.toUpperCase()] === 1
        ? flight?.journeys?.journey_1?.segmengtIds[0].departure
        : flight?.journeys.journey_0.segmengtIds[0].departure;
    const cityCodeTo =
      RETURN_TYPE[flightType.toUpperCase()] === 1
        ? flight?.journeys.journey_1?.segmengtIds[flight?.journeys.journey_1?.segmengtIds.length - 1].arrival
        : flight?.journeys.journey_0.segmengtIds[flight?.journeys.journey_0.segmengtIds.length - 1].arrival;

    const cityFrom =
      RETURN_TYPE[flightType.toUpperCase()] === 1
        ? AIRPORTS.find((el) => el.id === flight?.journeys?.journey_1?.segmengtIds[0]?.departure)?.city_name
        : AIRPORTS.find((el) => el.id === flight?.journeys.journey_0.segmengtIds[0].departure).city_name;
    const cityTo =
      RETURN_TYPE[flightType.toUpperCase()] === 1
        ? AIRPORTS.find((el) => el.id === flight?.journeys?.journey_1?.segmengtIds[flight?.journeys?.journey_1?.segmengtIds.length - 1].arrival)?.city_name
        : AIRPORTS.find((el) => el.id === flight?.journeys.journey_0.segmengtIds[flight?.journeys.journey_0.segmengtIds.length - 1].arrival).city_name;

    return {
      stops,
      departureTime,
      arrivalTime,
      hours:
        flightType === "outbound"
          ? departureTimeConverted.hours
          : returnTimeConverted.hours,
      minutes: flightType === "outbound" ? departureTimeConverted.minutes : returnTimeConverted.minutes,
      cityCodeFrom,
      cityCodeTo,
      cityFrom,
      cityTo,
      startingFlight,
      endingFlight,
      localDep,
      localArr,
    };
  }

  flightDetailsByFlight(flight, carriers = []) {
    if (!flight || !carriers.length) return {};
    const { inboundFlights, outboundFlights } =
      this.getInboundAndOutboundRoutes(flight);
    const airlineOutbound = this.getAirline(
      outboundFlights?.[0]?.airline,
      carriers
    );

    const airlineInbound = this.getAirline(
      inboundFlights?.[0]?.airline,
      carriers
    );
    const handBaggageInKg = `${(flight?.baggageMap.ADT[0]?.carryOnWeight) || 0}kg`;
    const holdBaggageInKg = `${(flight?.baggageMap.ADT[0]?.baggageWeight) || 0}kg`;
    const hasSelfTransfer = false;
    const flightClassCategory =
      SELECTED_CABINS[flight?.journeys.journey_0?.segmengtIds[0].cabinClass];
    const isVirtualInterlining = false;
    const numberOfSeats = 9;
    const price = flight?.totalPrice;
    const [firstPrice, secondPrice] = price?.toString()?.split();

    const {
      stops: obStops,
      departureTime: obDepartureTime,
      arrivalTime: obArrivalTime,
      hours: obHours,
      minutes: obMinutes,
      cityCodeFrom: obCityCodeFrom,
      cityCodeTo: obCityCodeTo,
      cityFrom: obCityFrom,
      cityTo: obCityTo,
      startingFlight: obStartingFlight,
      endingFlight: obEndingFlight,
      localDep: obLocalDep,
      localArr: obLocalArr,
    } = this.flightDetails(outboundFlights, flight, "outbound", carriers);

    const {
      stops: ibStops,
      departureTime: ibDepartureTime,
      arrivalTime: ibArrivalTime,
      hours: ibHours,
      minutes: ibMinutes,
      cityCodeFrom: ibCityCodeFrom,
      cityCodeTo: ibCityCodeTo,
      cityFrom: ibCityFrom,
      cityTo: ibCityTo,
      startingFlight: ibStartingFlight,
      endingFlight: ibEndingFlight,
      localDep: ibLocalDep,
      localArr: ibLocalArr,
    } = this.flightDetails(inboundFlights, flight, "inbound", carriers);

    const { hours, minutes } = calculateTotalTime(
      obHours,
      obMinutes,
      ibHours,
      ibMinutes
    );

    const response = {
      airlineOutbound,
      airlineInbound,
      handBaggageInKg,
      holdBaggageInKg,
      hasSelfTransfer,
      flightClassCategory,
      isVirtualInterlining,
      numberOfSeats,
      firstPrice,
      secondPrice,
      obStartingFlight,
      obArrivalTime,
      obCityCodeTo,
      obCityCodeFrom,
      obCityFrom,
      obCityTo,
      obHours,
      obStops,
      obMinutes,
      obDepartureTime,
      obEndingFlight,
      ibArrivalTime,
      ibCityCodeFrom,
      ibCityCodeTo,
      ibCityFrom,
      ibCityTo,
      ibDepartureTime,
      ibHours,
      ibStops,
      ibMinutes,
      ibStartingFlight,
      ibEndingFlight,
      totalOfObIbHoursAndMinutes: { hours, minutes },
      firstFlightPrice: ibStartingFlight?.price,
      obLocalDep,
      obLocalArr,
      ibLocalDep,
      ibLocalArr,
    };

    return response;
  }

  getTopLocationsWithPriceAndCity(
    locations = [],
    flights = [],
    locationIdAndCode = []
  ) {
    const routes = flights?.map((flight) => flight?.route)?.flat();




    const listOfLocations = [];
    locations.forEach(({ fly_to }) => {
      const [fly_to_value] = fly_to;

      const flightPrice = flights?.find(fl => fl?.route?.find(r => r.cityTo === fly_to_value));

      const cityDetails = locationIdAndCode?.find(
        (loc) => loc.code === fly_to_value || loc.id === fly_to_value
      );

      const route = routes?.find((r) => {
        return (
          r.cityCodeTo === fly_to_value ||
          cityDetails?.code === r.cityCodeTo ||
          cityDetails?.city === r.cityTo
        );
      });
      listOfLocations.push({
        ...route,
        city: cityDetails?.id || route?.cityTo,
      });
    });
    return listOfLocations;
  }

  async getCityImages(topLocationCities) {
    const dataObject = {};
    const cities = topLocationCities?.map((city) => city);
    const promises = topLocationCities?.map((city) =>
      GET_IMAGE_FOR_CITY(null, city)
    );

    try {
      const results = await Promise.allSettled(promises);
      if (results.length) {
        results?.forEach((result, index) => {
          if (result.status === "fulfilled") {
            const currentCity = cities[index];
            dataObject[currentCity] = result.value;
          }
        });
      }
    } catch (error) {
      console.error("Failed to settle all image promises: ", error);
    }

    return dataObject;
  }

  getImageUrlByCity(city, images) {
    return images[city];
  }

  async setPopularFlights(
    topLocations = [],
    geolocation,
    setCitiesImageUrl,
    setTopLocationsForView,
    tab,
    setTopFlightsHome,
    returnOrOneWayTopLocations,
    topLocationsPassengers
  ) {
    let locations = [];
    setTopLocationsForView([]);

    if (tab !== "popular") {
      const { NORTH_EAST, SOUTH_WEST } =
        GEOLOCATION_COORDINATES[tab.toUpperCase()];
      const response = await GET(
        GET_LOCATIONS_BY_BOX(
          SOUTH_WEST.latitude,
          SOUTH_WEST.longitude,
          NORTH_EAST.latitude,
          NORTH_EAST.longitude
        ),
        {},
        ITRAVEL_KEY_API
      );

      locations = response?.locations;
    } else {
      locations = topLocations?.locations;
    }

    const countries = await GET(GET_LIST_COUNTRY(geolocation?.city));

    localStorage.setItem("capital", countries?.[0]?.capital?.[0]);

    const formattedLocations = this.formatTopLocationsForMultiSearch(
      locations,
      countries?.[0]?.cca2 || "PRN",
      tab,
      returnOrOneWayTopLocations || "return"
    );

    localStorage.setItem("formattedLocations", JSON.stringify(formattedLocations));
    const { queryParams, payload } = GET_MULTICITY_REQUEST_PARAMS({
      flights: formattedLocations,
      adults: topLocationsPassengers?.adult,
      children: topLocationsPassengers?.children,
      babies: topLocationsPassengers?.infants,
    });

    const response = await POST(
      `${MULTI_CITY_ENDPOINT}${queryParams}`,
      {
        body: JSON.stringify(payload),
      },
      MULTICITY_KEY_API
    );

    const locationIdAndCode = locations?.map((location) => ({
      id: location?.city?.id || location.id,
      code: location.code,
      city: location?.city?.name,
    }));

    const topLocationsData = this.getTopLocationsWithPriceAndCity(
      formattedLocations,
      response?.data,
      locationIdAndCode
    );

    const topLocationCities = topLocationsData?.map((t) => t.city);
    const cityImages = await this.getCityImages(topLocationCities);
    setCitiesImageUrl(cityImages);

    setTopLocationsForView(uniqueItems("cityTo", topLocationsData));
    setTopFlightsHome(tab, false);
  }

  async saveBooking(
    lang = "en",
    locale = "en",
    passengers = [],
    booking_token,
    session_id,
    baggage = []
  ) {
    const payload = {
      health_declaration_checked: true,
      lang,
      locale,
      passengers,
      booking_token,
      session_id,
      baggage,
      booked_at: "itravel",
    };
    try {
      const result = await POST(
        BOOKING_SAVE,
        { body: JSON.stringify(payload) },
        ITRAVEL_KEY_API
      );
      return result;
    } catch (error) {
      return error;
    }
  }

  getTotalPassenger(adult = 0, children = 0, infants = 0) {
    return adult + children + infants;
  }

  checkFlights = async (flight, currency, adult, children, babies) => {
    const bnum = getItemFromLocalStorage("bnum");
    const response = await GET(
      `${BOOKING_CHECK_FLIGHT}?booking_token=${flight?.booking_token}&bnum=${bnum?.checked || 0
      }&adults=${adult}&children=${children}&infants=${babies}&currency=${currency}&affily=itravel`,
      {},
      ITRAVEL_KEY_API
    );
    return response;
  };
}
